import Layout from '@/layout/layout'

const classificationRouter = {
    path: '/platformOperation',
    component: Layout,
    redirect: 'noRedirect',
    name: '平台运营',
    children: [
        {
            path: 'shareCopy',
            component: () => import('@/views/platformOperation/shareCopy/index'),
            name: '回收文案',
            meta: { title: '回收文案', noCache: true }
        },
        {
            path: 'redPacketActivity',
            component: () => import('@/views/platformOperation/redPacketActivity/index'),
            name: '红包活动管理',
            meta: { title: '红包活动管理', noCache: true }
        },
        {
            path: 'activityControls',
            component: () => import('@/views/platformOperation/redPacketActivity/activityControls'),
            name: '红包活动配置',
            meta: { title: '红包活动配置', noCache: true }
        },
        {
            path: 'manualExamination',
            component: () => import('@/views/platformOperation/manualExamination/index'),
            name: '人工审单',
            meta: { title: '人工审单', noCache: true }
        },
        {
            path: 'quotationReview',
            component: () => import('@/views/platformOperation/quotationReview/index'),
            name: '报价审单',
            meta: { title: '报价审单', noCache: true }
        },
        {
            path: 'redbageRelease',
            component: () => import('@/views/platformOperation/redbageRelease/index'),
            name: '红包发放记录',
            meta: { title: '红包发放记录', noCache: true }
        },
        {
            path: 'redbageAccount',
            component: () => import('@/views/platformOperation/redbageAccount/index'),
            name: '红包账户',
            meta: { title: '红包账户', noCache: true }
        },
    ]
}

export default classificationRouter